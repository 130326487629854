(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Share modal
     * -- Modal to allow users to share a webpage via email, twitter...
     *
     * - Dependencies
     * -- Bootstrap WIP DELETE
     * -- google analytics WIP DELETE
     *
     * - API
     * -- To display the different share options it's necessary to fill the
     * attribute `shareEnabled` with the elements that you want to enable,
     * the options are:
     * shareEnabled: {
     *   email: true or { description:  'my description },
     *   twitter: true,
     *   facebook: true,
     *   pinterest: {
     *     description: 'my_description'
     *   },
     *   qr: {
     *     url: "url for qr in web"
     *     printQr: {
     *       			"printUrl",
     *       			"printSubHeader",
     *       			"printTextDiscoverMore",
     *       			"printTextOpenQR",
     *       			"printTitle",
     *       			"trailPictogramId",
     *       			"trailPictogramText",
     *       			"trailByAuthor",
     *       			"printDisclaimer"
     *     }
     *   },
     *   embeded: {
     *     "trailId"
     *   },
     *   whatsapp: true,
     * }
     *
     * The attributes `analyticsEvent` are used to categorizes the google
     * analytics event
     *
     */
    Vue.component("share-modal", {
        props: [
            "isModalOpen",
            "isUserLoggedIn",
            "socialLoginState",
            "recaptchaModule",
            "socialLoginModule",
            "permalink",
            "title",
            "i18n",
            "shareEnabled",
            "analytics",
            "isInImageGallery",
        ],
        data: function () {
            return {
                isLoginOpen: false,
                qrModalOpen: false,
                embededModalOpen: false,
                url: null,
            };
        },
        template: `
      <div v-if="isModalOpen" ref="shareModal" class="share-modal--mask">
        <div class="share-modal__wrapper">
          <div class="share-modal__content" ref="shareModalContent">
          
            <div class="share-modal__header">
              <div class="share-modal__title">
                <b>{{i18n.txtShareTitle}}</b>
              </div>
              <div class="share-modal__close">
                <button type="button" class="share-modal__close-button" aria-label="Close" @click="close">
                  <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
                </button>
              </div>
            </div>
            
            <div class="share-modal__buttons">
                <button v-if="shareEnabled.facebook" class="wlbutton wlbutton--ter-grey" title="Share to Facebook" @click="shareFacebook"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/facebook.svg"/>Facebook</button>
                <button v-if="shareEnabled.twitter" class="wlbutton wlbutton--ter-grey" title="Share to Twitter" @click="shareTwitter"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/twitter.svg"/>Twitter</button>
                <button v-if="shareEnabled.pinterest" class="wlbutton wlbutton--ter-grey" title="Share on Pinterest" @click="sharePinterest"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/pinterest.svg"/>Pinterest</button>
                <button v-if="shareEnabled.whatsapp" id="share-modal__buttons__whatsapp" class="wlbutton wlbutton--ter-grey" title="Share to Whatsapp" @click="shareWhatsapp"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/whatsapp.svg"/>Whatsapp</button>
                <button v-if="shareEnabled.qr" class="wlbutton wlbutton--ter-grey" title="Share with QR" @click="shareQR"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/qr.svg"/>{{i18n.txtShareQrCode}}</button>
                <button v-if="shareEnabled.embeded" id="share-modal__buttons__embed" class="wlbutton wlbutton--ter-grey" v-bind:title="i18n.showMinimap" @click="shareEmbeded"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/embed.svg"/>{{i18n.txtShareEmbedMap}}</button>
            </div>
            
            <p class="share-modal__link-text">{{i18n.txtShareSectionLink}}</p>
            <div class="share-modal__link">
              <input :value="url" readonly>
              <button @click="copyLink" class="wlbutton wlbutton--ter-grey"><img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/social/copylink.svg"/>{{i18n.txtCopyLink}}</button>
            </div>
            
          </div>
        </div>
        
      <embeded-modal v-if="shareEnabled.embeded"
      :isModalOpen="embededModalOpen"
        :title="title"
        :url="permalink"
        :i18n="i18n"
        :subdomain="subdomain"
        :trailId="shareEnabled.embeded.trailId"
        @closeEmbededModalEvent="closeEmbededModal"
      ></embeded-modal>
      
      <qr-modal v-if="shareEnabled.qr"
        :isModalOpen="qrModalOpen"
        :title="title"
        :url="shareEnabled.qr.url"
        :i18n="i18n"
        :printQr="shareEnabled.qr.printQr"
        :type="shareEnabled.qr.type"
        @closeQrModalEvent="closeQrModal"
      ></qr-modal>
        
      <!-- FULLSCREEN MODALS -->
      <login-modal v-if="!isUserLoggedIn"
              :isModalOpen="isLoginOpen"
              :state="socialLoginState"
              :recaptchaModule="recaptchaModule"
              :socialLoginModule="socialLoginModule"
              :i18n="i18n"
              @closeLoginModalEvent="closeLoginModal"
      >
      </login-modal>
    </div>
    `,
        mounted: function () {
            this.url = window.location.href;

            this.$eventBus.$on("shareModal_embedOpen", (payload) => {
                this.shareEmbeded();
            });
        },
        beforeDestroy: function () {},
        methods: {
            onClickOutside: onClickOutside,
            close: close,
            shareFacebook: shareFacebook,
            shareTwitter: shareTwitter,
            shareWhatsapp: shareWhatsapp,
            sharePinterest: sharePinterest,
            shareQR: shareQR,
            closeLoginModal: closeLoginModal,
            closeQrModal: closeQrModal,
            closeEmbededModal: closeEmbededModal,
            shareEmbeded: shareEmbeded,
            copyLink: copyLink,
        },
        computed: {
            subdomain: subdomain,
        },
        watch: {
            isModalOpen: isModalOpen,
        },
    });

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function isModalOpen(newIsModalOpen, oldIsModalOpen) {
        _doubleRaf(() => {
            if (newIsModalOpen) {
                _addOnClickOutsideEvent(this);
                _enableDocumentScroll(false);
            } else {
                _rmOnClickOutsideEvent(this);
                _enableDocumentScroll(true);
            }
        });
    }

    function close() {
        this.$emit("closeShareModalEvent", {});
    }

    function onClickOutside(event) {
        // stop click propagation for parent modals
        // without this action, the clickOutside event is triggered in the parent modal, closing it as well.
        event.stopPropagation();

        let component = this.$refs.shareModalContent;
        //The click is outside the component
        if (component && !component.contains(event.target)) {
            this.close();
        }
    }

    function subdomain() {
        let url = window.location.href;
        var matches = url.match(/https?:\/\/?(\w+).wikiloc.com/);
        //with https://es.wikiloc.com
        //return ["https://es.wikiloc.com", "es"]
        return matches[1];
    }

    function _openLoginModal() {
        this.isLoginOpen = true;
        setTimeout(
            function () {
                window.SocialLogin &&
                    SocialLogin.forceInit(this.socialLoginState);
            },
            200,
            this //share scope of parent
        );
    }

    function translate(text, hash) {
        if (text && hash) {
            var hash_keys = [];
            for (var i in hash) {
                if (hash.hasOwnProperty(i)) {
                    hash_keys.push(i);
                }
            }
            for (var q in hash_keys) {
                var regex = new RegExp("\\{" + hash_keys[q] + "\\}", "gi");
                text = text.replace(regex, hash[hash_keys[q]].toString());
            }
        }
        return text;
    }

    function _generateLink(utmMedium, utmSource, utmCampaign) {
        let separator = "%26";
        if (utmMedium === "twitter.com") {
            separator = "%26";
        }
        let link = this.permalink;
        if (link) {
            //sanitize permalink
            if (link.endsWith("#")) {
                link = link.substr(0, link.length - 1);
            }

            var match = link.match(/\?/);
            if (!match) {
                link += "?";
            } else if (match.index < link.length) {
                //contains some parameter
                if (utmMedium === "twitter.com") {
                    link = link.replaceAll("&", separator);
                }
                link += separator;
            }
            link +=
                "utm_source=" +
                utmSource +
                separator +
                "utm_medium=" +
                utmMedium +
                separator +
                "utm_campaign=" +
                utmCampaign;
            return link;
        }
    }

    function shareFacebook() {
        gtagEvent("share", {
            content_type: this.analytics.content_type,
            item_id: parseInt(this.analytics.item_id),
            method: "facebook",
            ref: this.analytics.ref,
        });

        window.open(
            "https://www.facebook.com/sharer.php?u=" +
                _generateLink.call(this, "social", "facebook.com", "share"),
            "share",
            "location=0,status=0,scrollbars=0,width=640,height=460"
        );
        return false;
    }

    function shareTwitter() {
        gtagEvent("share", {
            content_type: this.analytics.content_type,
            item_id: parseInt(this.analytics.item_id),
            method: "twitter",
            ref: this.analytics.ref,
        });

        window.open(
            "https://twitter.com/share?url=" +
                _generateLink.call(this, "social", "twitter.com", "share"),
            "share",
            "location=0,status=0,scrollbars=1,width=620,height=440"
        );
        return false;
    }

    function shareWhatsapp() {
        gtagEvent("share", {
            content_type: this.analytics.content_type,
            item_id: parseInt(this.analytics.item_id),
            method: "whatsapp",
            ref: this.analytics.ref,
        });
        window.open(
            "whatsapp://send?text=+" +
                this.permalink +
                "%3Futm_medium=social%26utm_source=whatsapp%26utm_campaign=share",
            "share",
            "location=0,status=0,scrollbars=1,width=620,height=440"
        );
    }

    function sharePinterest() {
        gtagEvent("share", {
            content_type: this.analytics.content_type,
            item_id: parseInt(this.analytics.item_id),
            method: "pinterest",
            ref: this.analytics.ref,
        });
        window.open(
            "https://pinterest.com/pin/create/link/?url=" +
                this.permalink +
                "%3Futm_medium=social%26utm_source=pinterestq%26utm_campaign=share" +
                "&media=" +
                this.shareEnabled.pinterest.image_url +
                "&description=" +
                this.shareEnabled.pinterest.description,
            "share",
            "location=0,status=0,scrollbars=1,width=620,height=440"
        );
        return false;
    }

    function shareQR() {
        if (!this.isUserLoggedIn) {
            _openLoginModal.call(this);
        } else {
            gtagEvent("share", {
                content_type: this.analytics.content_type,
                item_id: parseInt(this.analytics.item_id),
                method: "qr",
                ref: this.analytics.ref,
            });
            this.qrModalOpen = true;
        }
    }

    function shareEmbeded() {
        gtagEvent("share", {
            content_type: this.analytics.content_type,
            item_id: parseInt(this.analytics.item_id),
            method: "embed",
            ref: this.analytics.ref,
        });
        this.embededModalOpen = true;
    }

    function closeLoginModal() {
        this.isLoginOpen = false;
    }

    function closeQrModal() {
        this.qrModalOpen = false;
    }

    function closeEmbededModal() {
        this.embededModalOpen = false;
    }

    function copyLink() {
        const el = document.createElement("textarea");
        el.value = this.url;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
        gtagEvent("share", {
            content_type: this.analytics.content_type,
            item_id: parseInt(this.analytics.item_id),
            method: "copy_link",
            ref: this.analytics.ref,
        });
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    function _addOnClickOutsideEvent(vueInstance) {
        vueInstance.$refs.shareModal.addEventListener(
            "click",
            vueInstance.onClickOutside
        );
    }

    function _rmOnClickOutsideEvent(vueInstance) {
        if (vueInstance.$refs.shareModal) {
            vueInstance.$refs.shareModal.removeEventListener(
                "click",
                vueInstance.onClickOutside
            );
        }
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }
})();
